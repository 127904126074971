import { useContext, useMemo } from 'react'
import {
  generatePath,
  matchPath,
  useLocation,
  PathMatch,
} from 'react-router-dom'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import useAccessRules from './useAccessRules'
import type User from 'models/User'
import AuthProviderUser from 'models/AuthProviderUser'
import FirmUser from 'models/FirmUser'
import LawyerUser from 'models/LawyerUser'
import SubscriberUser from 'models/SubscriberUser'
import { routes, redirects } from 'routes'
import log from 'loglevel'
import AnonymousUser from 'models/AnonymousUser'

const buildRedirectUrl = (pathname: string, search: string): string => {
  if (
    ![
      '/',
      routes.login.path,
      routes.signedOut.path,
      routes.forgotPassword.path,
    ].includes(pathname)
  ) {
    const encodedPathname = encodeURIComponent(pathname)
    const queryParams = new URLSearchParams(search)
    const encodedSearch = queryParams.toString()
    const encoded =
      encodedSearch !== ''
        ? `${encodedPathname}?${encodedSearch}`
        : encodedPathname
    return `?redirect=${encoded}`
  }

  return ''
}

export const getRedirectUrl = (search: string): string | null => {
  if (search.includes('redirect=')) {
    const urlParams = new URLSearchParams(location.search)
    const redirect = urlParams.get('redirect')
    if (redirect != null) {
      const decoded = decodeURIComponent(redirect)
      return decoded
    }
  }
  return null
}

export const useDefaultRoute = (user: User): string => {
  const { pathname, search } = useLocation()
  const redirectUrl = getRedirectUrl(search)

  log.debug('Redirect URL:', redirectUrl)
  if (redirectUrl != null) {
    return redirectUrl
  }

  if (user instanceof AuthProviderUser) {
    return routes.signup.path
  } else if (user instanceof LawyerUser || user instanceof FirmUser) {
    return routes.legalMatterListing.path
  } else if (user instanceof SubscriberUser) {
    if (!user.hasAnActiveSubscription()) {
      return routes.checkoutManageProducts.path
    }
    return routes.legalMatterListing.path
  } else if (user instanceof AnonymousUser) {
    // Attach a redirect query param to redirect later
    return routes.login.path + buildRedirectUrl(pathname, search)
  }

  return '/'
}

const useRedirect = (): {
  loading: boolean
  redirect?: string
} => {
  const { pathname } = useLocation()
  const { loading, user } = useContext(UserProfileContext)
  const { userIsOneOf, userHasRole } = useAccessRules(user)
  const defaultPath = useDefaultRoute(user)

  const allRoutes = useMemo(() => Object.values(routes), [])

  return useMemo(() => {
    if (loading) {
      return { loading }
    }

    log.debug(`useRedirect: [${pathname}]`)

    if (pathname === '/') {
      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    // Deal with old routes which have been mapped to new routes
    const oldRedirect = Object.keys(redirects).reduce<string | null>(
      (acc, route) => {
        if (acc != null) return acc
        const matched = matchPath(route, pathname)
        if (matched != null) {
          if (matched.params != null) {
            const params = Object.keys(matched.params).reduce((acc, key) => {
              acc[key] = matched.params[key] ?? null
              return acc
            }, {})
            return generatePath(redirects[route], params)
          }
          return redirects[route]
        }
        return matched ?? null
      },
      null,
    )

    if (oldRedirect != null) {
      log.debug('Old route redirect ->', oldRedirect)
      return {
        loading: false,
        redirect: oldRedirect,
      }
    }

    const rule = allRoutes.find((route) => {
      return matchPath(route.path, pathname)
    })

    if (rule == null) {
      return { loading: false, redirect: '/error-not-found' }
    }

    if (rule.allowedUserTypes != null && !userIsOneOf(rule.allowedUserTypes)) {
      log.debug('User is not one of the allowed types', pathname)

      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    if (rule.requiresRole != null && !userHasRole(rule.requiresRole)) {
      log.debug('User does not have the required role', pathname)
      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    log.debug('No redirect', pathname)
    return { loading: false }
  }, [loading, pathname, userIsOneOf, userHasRole, allRoutes, defaultPath])
}

export default useRedirect
