import { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useLoaderData, useNavigate } from 'react-router-dom'
import IntakeFormStep from '../forms/IntakeFormStep'
import {
  type JsonFormMutationResponse,
  JsonFormType,
  LegatMatterIntakeKindsDocument,
} from '../../../@types/generated/graphql'
import type { IntakeMutationProps } from '../../../@types/JsonSchemaForm'
import { routes } from 'routes'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import { UserProfileContext } from '../../providers/UserProfileProvider'
import { enqueueSnackbar } from 'notistack'
import SubscriberUser from 'models/SubscriberUser'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import useLegalMatter from 'utils/useLegalMatter'
import useIntakeForm from 'utils/useIntakeForm'

export const createLegalMatterLoader = ({ params }): Record<string, string> => {
  if (params?.legalMatterKind != null && params?.legalMatterKindKey != null) {
    return {
      legalMatterKind: params.legalMatterKind,
      legalMatterKindKey: params.legalMatterKindKey,
    }
  } else {
    throw new Error('Not Found')
  }
}

const CreateLegalMatter = (): JSX.Element => {
  const navigate = useNavigate()
  const { legalMatterKind: formType, legalMatterKindKey }: any = useLoaderData()
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false)
  const { user } = useContext(UserProfileContext)
  const { createLegalMatter } = useLegalMatter()
  const { saveIntakeData, intakeLoading, intakeError } = useIntakeForm()

  const { data, loading } = useQuery(LegatMatterIntakeKindsDocument, {
    variables: {
      key: legalMatterKindKey,
    },
  })

  const getPresetData = (formType: JsonFormType): any => {
    if (user === null) return {}

    let age = 0

    if (!(user instanceof SubscriberUser)) {
      return
    }

    if (user.profile.dateOfBirth !== null) {
      const dob = new Date(user.profile.dateOfBirth)
      const monthDiff = Date.now() - dob.getTime()
      const ageDate = new Date(monthDiff)
      const year = ageDate.getUTCFullYear()
      age = Math.abs(year - 1970)
    }
    const presetData: { [key in JsonFormType]?: any } = {
      [JsonFormType.UncontestedAdoption]: {
        additional_info: {
          date_of_birth: user.profile.dateOfBirth ?? undefined,
          contact_info: {
            email_address: user.profile.email ?? undefined,
            contact_phone: user.profile.phone ?? undefined,
          },
        },
        subscriber_info: {
          personal_information: {
            full_name: `${String(user.profile.firstName)} ${String(
              user.profile.lastName,
            )}`,
            gender: user.profile.gender ?? undefined,
            location: {
              street: user.profile.location?.street ?? undefined,
              apt_number: user.profile.location?.aptNumber ?? undefined,
              address: {
                state: user.profile.location?.state.code ?? undefined,
                city: user.profile.location?.city ?? undefined,
                zip_code: user.profile.location?.zipCode ?? undefined,
              },
            },
          },
        },
      },
      [JsonFormType.WillQuestionnaire]: {
        additional_info: {
          date_of_birth: user.profile.dateOfBirth ?? undefined,
          contact_info: {
            email_address: user.profile.email ?? undefined,
            contact_phone: user.profile.phone ?? undefined,
          },
        },
        personal_info: {
          first_name: user.profile.firstName ?? undefined,
          middle_name: user.profile.middleName ?? undefined,
          last_name: user.profile.lastName ?? undefined,
          gender: user.profile.gender ?? undefined,
          age,
        },
        address: {
          street: user.profile.location?.street ?? undefined,
          apt_number: user.profile.location?.aptNumber ?? undefined,
          address: {
            state: user.profile.location?.state.code ?? undefined,
            city: user.profile.location?.city ?? undefined,
            zip_code: user.profile.location?.zipCode ?? undefined,
          },
        },
      },
      [JsonFormType.BusinessFormation]: {
        general_info: {
          business_address: {
            street: user.profile.location?.street ?? undefined,
            apt_number: user.profile.location?.aptNumber ?? undefined,
            address: {
              state: user.profile.location?.state.code ?? undefined,
              city: user.profile.location?.city ?? undefined,
              zip_code: user.profile.location?.zipCode ?? undefined,
            },
          },
          contact_person: {
            contact_person_name: `${String(user.profile.firstName)} ${String(
              user.profile.lastName,
            )}`,
            contact_person_phone: user.profile.phone ?? undefined,
            contact_person_email: user.profile.email ?? undefined,
          },
        },
      },
      [JsonFormType.GeneralLegalMatter]: {
        additional_info: {
          contact_info: {
            email_address: user.profile.email ?? undefined,
            mobile_phone: user.profile.phone ?? undefined,
          },
        },
        personal_info: {
          full_name: `${String(user.profile.firstName)} ${String(
            user.profile.lastName,
          )}`,
          date_of_birth: user.profile.dateOfBirth ?? undefined,
          gender: {
            other_gender_value: user.profile.gender ?? undefined,
          },
        },
        address: {
          street: user.profile.location?.street ?? undefined,
          apt_number: user.profile.location?.aptNumber ?? undefined,
          address: {
            state: user.profile.location?.state.code ?? undefined,
            city: user.profile.location?.city ?? undefined,
            zip_code: user.profile.location?.zipCode ?? undefined,
          },
        },
      },
    }

    return presetData[formType]
  }

  const getPresetUISchema = (formType: JsonFormType): any => {
    return {}
  }

  const onSubmit = ({ formType, formData }: IntakeMutationProps): void => {
    void saveIntakeData({
      variables: {
        formType,
        data: formData,
      },
      onCompleted: (data) => {
        const jsonFormSave = data.jsonFormSave as JsonFormMutationResponse
        if (jsonFormSave.success) {
          setSubmissionSuccess(true)
          if (user !== null) {
            void createLegalMatter({
              variables: {
                subscriberRef: user.key,
                kindRef: legalMatterKindKey,
                intakeDataRef: jsonFormSave.formData.key,
              },
              onCompleted: (data) => {
                if (data.legalMatterCreate.success) {
                  enqueueSnackbar(
                    'The legal matter has been successfully created.',
                    {
                      variant: 'success',
                    },
                  )
                  navigate(routes.legalMatterListing.path, {
                    state: 'REFETCH_REQUIRED',
                  })
                } else {
                  enqueueSnackbar(
                    'Legal Matter could not be created at the moment. Try again in a few seconds',
                    {
                      variant: 'error',
                    },
                  )
                }
              },
            })
          }
        }
      },
    })
  }

  if (user === null) return <ErrorScreen content="No user found" />

  if (loading) {
    return <LegalMatterListLoading numberOfSkeletons={1} />
  }

  return (
    <>
      <Typography
        variant="h1"
        gutterBottom
        data-testid="CreateLegalMatter-pageTitle"
      >
        Request Legal Service
      </Typography>
      {data && (
        <IntakeFormStep
          formType={data.legalMatterKinds[0].intakeForm}
          onSubmit={onSubmit}
          submissionErrors={intakeError?.jsonFormSave}
          submissionSuccess={submissionSuccess}
          submitting={intakeLoading}
          presetData={getPresetData(data.legalMatterKinds[0].intakeForm)}
          presetUiSchema={getPresetUISchema(
            data.legalMatterKinds[0].intakeForm,
          )}
        />
      )}
    </>
  )
}

export default CreateLegalMatter
