import { useContext } from 'react'
import LawyerUser from 'models/LawyerUser'
import FirmUser from 'models/FirmUser'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import LawyerLegalMatterListing from './LawyerLegalMatterListing'
import SubscriberLegalMatterListing from './SubscriberLegalMatterListing'
import FirmLegalMatterListing from './FirmLegalMatterListing'

const LegalMatterListing = (): JSX.Element | null => {
  const { user } = useContext(UserProfileContext)

  if (user instanceof FirmUser) {
    return (
      <>
        <FirmLegalMatterListing></FirmLegalMatterListing>
      </>
    )
  }

  if (user instanceof LawyerUser) {
    return (
      <>
        <LawyerLegalMatterListing></LawyerLegalMatterListing>
      </>
    )
  }

  return (
    <>
      <SubscriberLegalMatterListing></SubscriberLegalMatterListing>
    </>
  )
}

export default LegalMatterListing
