import React from 'react'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from 'view/components/atoms/ItemBoundingBox'
import LegalMatterItemBody from 'view/components/molecules/LegalMatterItemBody'
import ListItemBoxHeading from 'view/components/molecules/ListItemBoxHeading'
import {
  type LegalMatter,
  type LawyerUser,
  type SubscriberUser,
  LegalMatterStatus,
} from '../../../@types/generated/graphql'
import { resolveSubscriberName, friendlyUUID } from 'utils/StringFormatter'
import { DateFormatter } from 'utils/dateUtils'
import { type StatusLabels } from 'utils/StatusMapper'
import useTestId from 'utils/useTestId'
import log from 'loglevel'

type PartialLawyer = Pick<LawyerUser, 'firstName' | 'lastName'>
type PartialSubscriber = Pick<
  SubscriberUser,
  'firstName' | 'lastName' | 'subscriptionActive'
>
type PartialLegalMatter = Pick<
  LegalMatter,
  'calendarEvents' | 'createdTimestamp' | 'key' | 'kind' | 'rating' | 'status'
> & { subscriber: PartialSubscriber } & {
  assignedLawyer?: PartialLawyer | null
}

interface LegalMatterListItemProps {
  legalMatter: PartialLegalMatter
  textButton?: string
  legalMatterStatusLabels?: StatusLabels
  onClickMenu?: (legalMatter: PartialLegalMatter) => void
  onClickButton?: (legalMatter: PartialLegalMatter) => void
  onClickViewDetails?: (legalMatter: PartialLegalMatter) => void
  onChangeRating?: (legalMatter: PartialLegalMatter) => void
}

const LegalMatterListItem = ({
  legalMatter,
  textButton,
  legalMatterStatusLabels,
  onClickMenu,
  onClickButton,
  onClickViewDetails,
  onChangeRating,
  ...params
}: LegalMatterListItemProps): JSX.Element => {
  const shortUUID = friendlyUUID(legalMatter.key)
  const formattedCreationTime = DateFormatter(legalMatter.createdTimestamp)
  const testId = useTestId(params)
  const LegalMatterRating = (
    <Rating
      name="simple-controlled"
      value={legalMatter.rating}
      aria-label="Rating Stars"
      readOnly={onChangeRating == null}
      onChange={
        onChangeRating != null
          ? () => {
              onChangeRating(legalMatter)
            }
          : undefined
      }
    />
  )
  return (
    <ItemBoundingBox {...testId}>
      <ListItemBoxHeading
        title={
          <Grid container>
            <Grid item sx={{ marginRight: '6px' }}>
              <Chip
                label={
                  <Typography variant="body1" data-chromatic="ignore">
                    {shortUUID}
                  </Typography>
                }
                sx={{
                  minWidth: 85,
                }}
              />
            </Grid>
            <Grid item pt={1} ml={0}>
              {legalMatter?.kind?.name != null &&
                legalMatter?.kind?.category != null && (
                  <Typography variant="h5">{`${legalMatter.kind.category} / ${legalMatter.kind.name}`}</Typography>
                )}
            </Grid>
          </Grid>
        }
        subtitle={
          formattedCreationTime != null
            ? `Created: ${formattedCreationTime}`
            : undefined
        }
        midContent={legalMatter.rating != null ? LegalMatterRating : undefined}
        infoPill={
          !legalMatter.subscriber.subscriptionActive ? (
            <Chip
              label="LEGAL PLAN EXPIRED"
              data-testid="legal-matter-status"
              color="info"
              size="small"
            />
          ) : undefined
        }
        onClickMenu={
          onClickMenu != null
            ? () => {
                onClickMenu(legalMatter)
              }
            : undefined
        }
      />
      <hr />
      <LegalMatterItemBody
        clientName={resolveSubscriberName(
          legalMatter?.subscriber?.firstName,
          legalMatter?.subscriber?.lastName,
        )}
        lawyerName={resolveSubscriberName(
          legalMatter?.assignedLawyer?.firstName,
          legalMatter?.assignedLawyer?.lastName,
        )}
        nextEvent={legalMatter?.calendarEvents?.[0]}
        statusValue={
          legalMatterStatusLabels?.[legalMatter.status] ??
          legalMatterStatusLabels?.[LegalMatterStatus.Unassigned]
        }
        legalMatterType={legalMatter.kind?.name}
        textButton={textButton}
        onClickButton={
          onClickButton != null
            ? () => {
                onClickButton(legalMatter)
              }
            : undefined
        }
        onClickViewDetails={() => {
          if (onClickViewDetails !== undefined) {
            onClickViewDetails(legalMatter)
          }
        }}
      />
    </ItemBoundingBox>
  )
}

export default LegalMatterListItem
