import { useContext, useEffect } from 'react'
import { useQuery, NetworkStatus } from '@apollo/client'
import {
  CursorDirection,
  SubscriberLegalMatterListingDocument,
} from '../../../@types/generated/graphql'

import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'

import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LegalMatterListItem from 'view/components/organisms/LegalMatterListItem'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import FiltersLegalMatters from 'view/components/molecules/FiltersLegalMatters'

import { EventProviderContext } from 'view/providers/EventProvider'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import {
  LegalMatterFilterType,
  SubscriberLegalMatterStatusLabels,
} from 'utils/StatusMapper'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import SubscriberUser from 'models/SubscriberUser'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import { paginationLimits } from 'config'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import { useLegalMatterFilters } from '../../../utils/useLegalMatterFilters'

const SubscriberLegalMatterListing = (): JSX.Element => {
  const componentIdentifier = SubscriberLegalMatterListing.name
  const { user } = useContext(UserProfileContext)
  const subscriber = user as SubscriberUser
  const hasSubscription: boolean = subscriber.hasAnActiveSubscription()
  const navigate = useNavigate()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()

  const { filters, filterName, statusMapper, handleStatusChange } =
    useLegalMatterFilters(LegalMatterFilterType.SUBSCRIBER, user?.roles)

  const { data, networkStatus, error, fetchMore } = useQuery(
    SubscriberLegalMatterListingDocument,
    {
      skip: user?.key == null,
      notifyOnNetworkStatusChange: true,
      variables: {
        ...filters,
        subscriberRef: user?.key ?? null,
        limit: paginationLimits.legalMatters,
        cursor: null,
        cursorDirection: CursorDirection.Next,
        calendarEventsLimit: paginationLimits.calendarEvents,
        calendarEventsCursor: null,
        calendarEventsCursorDirection: CursorDirection.Next,
      },
    },
  )

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      ['LegalMatterClaimed'],
      [SubscriberLegalMatterListingDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables
  ) {
    return <LegalMatterListLoading numberOfSkeletons={5} />
  }

  if (error !== undefined) {
    return (
      <ErrorScreen content="Sorry, we can not show results, try again later" />
    )
  }

  const onClickLoadMore = (): void => {
    if (data?.legalMatters.pageInfo.endCursor != null) {
      void fetchMore({
        variables: {
          cursor: data?.legalMatters.pageInfo.endCursor,
        },
      })
    }
  }

  const renderNoResultsMessage = (filter: string): JSX.Element => {
    let message
    switch (filter) {
      case 'New':
        message = 'There are no new legal matters.'
        break

      case 'Current':
        message = 'There are no current legal matters.'
        break

      case 'Past':
        message = 'There are no past legal matters.'
        break

      case 'All':
      default:
        message = 'There are no legal matters.'
        break
    }

    return (
      <>
        <Alert severity="info" icon={false}>
          <Typography gutterBottom>{message}</Typography>
        </Alert>
        {filter === 'All' && (
          <Alert severity="info" icon={false} sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              Next steps:
            </Typography>
            <ul>
              <li>
                Click on the Request Legal Service button to open a new legal
                matter
              </li>
              <li>Choose the kind of legal matter you want to create</li>
              <li>
                Complete all the intake information required for your specific
                kind of legal matter
              </li>
              <li>
                Your provider law firm will contact you once the legal matter
                has been created
              </li>
            </ul>
          </Alert>
        )}
      </>
    )
  }

  return (
    <Box sx={{ paddingBottom: '24px' }}>
      {!hasSubscription && (
        <>
          <Alert severity="warning" icon={false} sx={{ mb: 3 }} role="alert">
            <Typography variant="h5" gutterBottom>
              You must have an active legal plan to request legal services.{' '}
              <Link
                href={routes.checkoutManageProducts.path}
                sx={{ fontSize: '16px', cursor: 'pointer' }}
              >
                {`Get a legal plan`}
              </Link>{' '}
              and become a LegalFix member today!
            </Typography>
          </Alert>
        </>
      )}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item mb={2}>
          <FiltersLegalMatters
            selectedStatus={filterName}
            statusList={statusMapper}
            onChange={handleStatusChange}
          />
        </Grid>
        <Grid item mb={2}>
          <Button
            endIcon={<AddCircleSharpIcon sx={{ color: 'white' }} />}
            onClick={() => {
              navigate(routes.legalServiceSelection.path)
            }}
            aria-label="Legal Matter Kinds Dropdown Button"
            data-testid="create-legal-matter-dropdown"
            disabled={!hasSubscription}
            variant="contained"
            color="primary"
            role="button"
          >
            Request Legal Service
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      {data?.legalMatters?.edges?.length === 0 &&
        renderNoResultsMessage(filterName)}
      {data?.legalMatters?.edges?.map((legalMatter, idx: number) => {
        return (
          <LegalMatterListItem
            key={`legal-matter-item-${idx}`}
            data-testid={`legal-matter-item`}
            legalMatter={legalMatter.node}
            legalMatterStatusLabels={SubscriberLegalMatterStatusLabels}
            onClickButton={() => {
              alert('I will assign an Attorney')
            }}
            onClickViewDetails={(legalMatter) => {
              navigate(
                routes.legalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
          />
        )
      })}
      {data != null && data.legalMatters.pageInfo.hasNextPage && (
        <LoadingButton
          loading={networkStatus === NetworkStatus.fetchMore}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Load More"
          onClick={onClickLoadMore}
          disabled={!data.legalMatters.pageInfo.hasNextPage}
          data-testid="loading-button"
        >
          Load More
        </LoadingButton>
      )}
    </Box>
  )
}

export default SubscriberLegalMatterListing
