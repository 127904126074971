import React from 'react'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NameValueLabel from 'view/components/atoms/NameValueLabel'
import styles from './ListItemBoxHeading.module.css'

interface Props {
  title?: React.ReactNode | string
  midContent?: React.ReactNode | string
  subtitle?: React.ReactNode | string
  disableMenu?: boolean
  infoPill?: React.ReactNode | string
  onClickMenu?: (event: React.MouseEvent) => void
}

const ListItemBoxHeading = ({
  title,
  subtitle,
  midContent,
  disableMenu,
  infoPill,
  onClickMenu,
}: Props): JSX.Element => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
    >
      <Grid
        item
        container
        data-testid="listitemboxheading-title"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item data-testid="listitemboxheading-title-container">
          {typeof title === 'string' ? (
            <NameValueLabel
              typographyProps={{ variant: 'h5' }}
              value={title != null ? String(title) : '&nbsp;'}
            />
          ) : (
            title
          )}
          {infoPill !== undefined && infoPill}
        </Grid>
        {midContent != false && (
          <Grid item lg="auto" mt="6px">
            {midContent}
          </Grid>
        )}
        <Grid item>
          <Typography
            paragraph
            className={styles.subtitle}
            data-testid="createdOn"
            my={0}
          >
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      {!(disableMenu ?? false) && (
        <Grid item>
          <IconButton
            data-testid="listitemboxheading-dotmenu"
            aria-label="Open Options Menu Button"
            onClick={(event) => {
              if (onClickMenu != null) {
                onClickMenu(event)
              }
            }}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default ListItemBoxHeading
