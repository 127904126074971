import { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import {
  JsonFormType,
  type MutationError,
} from '../../../@types/generated/graphql'
import IntakeFormStep from '../forms/IntakeFormStep'
import { type IntakeMutationProps } from '../../../@types/JsonSchemaForm'
import useFirmUser from 'utils/useFirmUser'
import useSubscriberUser from 'utils/useSubscriberUser'

const userKindFormsMap: Record<string, JsonFormType> = {
  FirmUser: JsonFormType.FirmUserRegistration,
  LawyerUser: JsonFormType.LawyerUserRegistration,
  SubscriberUser: JsonFormType.UserSubscription,
}

const UserProfile = (): JSX.Element => {
  const { user } = useContext(UserProfileContext)
  const userKind = user.profile.__typename
  const [editMode, setEditMode] = useState<boolean>(false)
  const [errors, setErrors] = useState<MutationError | undefined>(undefined)
  const { updateSubscriberUser, subscriberUserLoading } = useSubscriberUser()
  const { updateFirmUser, firmUserLoading } = useFirmUser()

  const handleEditClick = (): void => {
    setEditMode((prevState) => {
      return !prevState
    })
  }

  const onSubmit = ({ formData }: IntakeMutationProps): void => {
    setErrors(undefined)
    if (['LawyerUser', 'FirmUser'].includes(user.profile.__typename)) {
      void updateFirmUser({
        variables: {
          firmUserKey: user.key,
          formType: userKindFormsMap[userKind],
          data: formData,
        },
        onCompleted: (data) => {
          if (data?.firmUserUpdate?.__typename === 'MutationError') {
            setErrors(data.firmUserUpdate)
          }
          setEditMode((prevState) => !prevState)
        },
      })
    } else if (user.profile.__typename === 'SubscriberUser') {
      void updateSubscriberUser({
        variables: {
          subscriberKey: user.key,
          data: formData,
        },
        onCompleted: (data) => {
          if (data?.subscriberUpdate?.__typename === 'MutationError') {
            setErrors(data.subscriberUpdate)
          }
          setEditMode((prevState) => !prevState)
        },
      })
    } else {
      throw new Error(`Invalid user type ${user.profile.__typename}`)
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h6" data-testid="profilePage-title">
            Your Profile information:
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditClick}
            data-testid="profilePage-edit-button"
          >
            {editMode ? 'Cancel' : 'Edit profile'}
          </Button>
        </Grid>
      </Grid>

      <IntakeFormStep
        formType={userKindFormsMap[userKind]}
        onSubmit={onSubmit}
        submissionErrors={errors}
        submitting={subscriberUserLoading || firmUserLoading}
        presetData={{
          userInfo: {
            first_name: user?.profile?.firstName ?? '',
            middle_name: user?.profile?.middleName ?? '',
            last_name: user?.profile?.lastName ?? '',
            email: user?.profile?.email ?? '',
            gender: user?.profile?.gender ?? '',
            date_of_birth: user?.profile?.dateOfBirth ?? '',
            phone: user?.profile?.phone ?? '',
            location_street: user?.profile?.location?.street ?? '',
            practice_areas: user?.profile?.practiceAreas ?? '',
          },
          location: {
            street: user.profile?.location?.street ?? '',
            apt_number: user.profile?.location?.aptNumber ?? '',
            address: {
              state: user.profile?.location?.state?.code ?? '',
              city: user.profile?.location?.city ?? '',
              zip_code: user.profile?.location?.zipCode ?? '',
            },
          },
        }}
        presetUiSchema={{
          'ui:readonly': !editMode,
          userInfo: {
            email: { 'ui:readonly': true },
          },
        }}
      />
    </>
  )
}

export default UserProfile
