import useRedirect from 'utils/useRedirect'
import { Navigate } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import log from 'loglevel'
import PageLayout from '../templates/PageLayout'

const RedirectHandler = (): JSX.Element => {
  const { loading, redirect } = useRedirect()

  if (loading) {
    return <LinearProgress />
  }

  if (redirect != null) {
    log.debug('RH redirecting ->', redirect)
    return <Navigate to={redirect} replace />
  }
  log.debug('RH printing layout')

  return <PageLayout />
}

export default RedirectHandler
