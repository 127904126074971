import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface LegalMatterRequestActionProps {
  requestMessage?: string
  textButtonOptionA?: string
  textButtonOptionB?: string
  textButtonOptionC?: string
  onClickOptionAccept?: () => void
  onClickOptionDecline?: () => void
  onClickOptionAssignLawyers?: () => void
}

const LegalMatterRequestAction = ({
  requestMessage,
  textButtonOptionA,
  textButtonOptionB,
  textButtonOptionC,
  onClickOptionAccept,
  onClickOptionDecline,
  onClickOptionAssignLawyers,
}: LegalMatterRequestActionProps): JSX.Element => {
  const showMessage = requestMessage !== undefined
  const buttonGridColumns = showMessage ? 6 : 12
  const contentJustification = showMessage ? 'flex-start' : 'center'

  return (
    <>
      <Box
        sx={{
          width: '97%',
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'primary.main',
          padding: 3,
          left: 0,
          zIndex: 2,
        }}
      >
        <Grid container data-testid="legalMatterRequestAction-container">
          {showMessage && (
            <Grid item xs={6}>
              <Typography
                variant="h3"
                sx={{ color: '#fff' }}
                data-testid="legalMatterRequestAction-text"
              >
                {requestMessage}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={buttonGridColumns}
            container
            justifyContent={contentJustification}
          >
            {textButtonOptionA !== undefined &&
              onClickOptionAccept !== undefined && (
                <Button
                  disableElevation
                  variant="outlined"
                  color="secondary"
                  data-testid="legalMatterRequestAction-buttonOptionA"
                  onClick={onClickOptionAccept}
                  sx={{ marginRight: 2 }}
                >
                  {textButtonOptionA}
                </Button>
              )}
            {textButtonOptionC !== undefined &&
              onClickOptionAssignLawyers !== undefined && (
                <Button
                  disableElevation
                  variant="outlined"
                  color="secondary"
                  data-testid="legalMatterRequestAction-buttonOptionC"
                  onClick={onClickOptionAssignLawyers}
                  sx={{ marginRight: 2 }}
                >
                  {textButtonOptionC}
                </Button>
              )}
            {textButtonOptionB !== undefined &&
              onClickOptionDecline !== undefined && (
                <Button
                  disableElevation
                  variant="contained"
                  color="error"
                  data-testid="legalMatterRequestAction-buttonOptionB"
                  onClick={onClickOptionDecline}
                >
                  {textButtonOptionB}
                </Button>
              )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default LegalMatterRequestAction
