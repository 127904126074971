/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { type FieldProps } from '@rjsf/utils'
import { type FC, type SyntheticEvent, useCallback } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { gql } from '../../../@types/generated'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import log from 'loglevel'

const filter = createFilterOptions<string>()

export const usStatesQuery = gql(/* GraphQL */ `
  query UsStateDatabase($operationalStatesOnly: Boolean) {
    usLocations(operationalStatesOnly: $operationalStatesOnly) {
      states {
        stateCode
        stateName
      }
    }
  }
`)

export const usCitiesQuery = gql(/* GraphQL */ `
  query UsCityDatabase($stateCode: String, $cityKeyword: String) {
    usLocations(stateCode: $stateCode, cityKeyword: $cityKeyword) {
      cities {
        city
        countyName
      }
    }
  }
`)

const RJSFAddressField: FC<FieldProps<Record<string, any>>> = ({
  schema,
  hideError,
  readonly,
  idSeparator,
  idSchema,
  onChange,
  formData,
  required,
}) => {
  const state = formData?.state
  const city = formData?.city
  const zipCode = formData?.zip_code
  const [stateOptionsOpen, setStateOptionsOpen] = React.useState(false)
  const [getStates, { loading: statesQueryLoading, data: statesData }] =
    useLazyQuery(usStatesQuery)

  const {
    stateFieldTitle,
    cityFieldTitle,
    zipCodeFieldTitle,
    operationalStatesOnly,
  } = schema
  const showErrors = hideError !== undefined && !hideError
  const isRequired = required !== undefined && required
  const showStateError = showErrors && isRequired && state === ''
  const showCityError = showErrors && isRequired && city === ''
  const showZipCodeError = showErrors && isRequired && zipCode === ''

  const states =
    statesData?.usLocations?.states?.map(
      (state: { stateCode: string }) => state.stateCode,
    ) ?? []

  const handleStateChange = (
    _event: SyntheticEvent,
    value: string | null,
  ): void => {
    if (value === undefined || value === null) return

    onChange(
      formData !== undefined
        ? { ...formData, state: value, city: '', zip_code: '' }
        : { state: value, city: '', zip_code: '' },
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl
            required={required}
            fullWidth
            error={showStateError ?? false}
          >
            <Autocomplete
              id={`${idSchema.$id}${idSeparator}state`}
              open={stateOptionsOpen}
              disabled={readonly}
              onOpen={() => {
                setStateOptionsOpen(true)
                void (async () => {
                  await getStates({
                    variables: {
                      operationalStatesOnly: operationalStatesOnly ?? false,
                    },
                  })
                })()
              }}
              onClose={() => {
                setStateOptionsOpen(false)
              }}
              onChange={handleStateChange}
              value={state ?? ''}
              options={states}
              loading={stateOptionsOpen || statesQueryLoading}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  name={`${idSchema.$id}${idSeparator}state`}
                  {...params}
                  required={required}
                  disabled={readonly}
                  error={showStateError ?? false}
                  variant="outlined"
                  label={stateFieldTitle ?? 'State'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateOptionsOpen && statesQueryLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required={required}
            fullWidth
            error={showCityError ?? false}
          >
            <TextField
              id={`${idSchema.$id}${idSeparator}city`}
              name={`${idSchema.$id}${idSeparator}city`}
              required={required}
              disabled={readonly}
              error={showCityError ?? false}
              variant="outlined"
              label={cityFieldTitle ?? 'City'}
              value={city ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newCity = event.target.value
                onChange(
                  formData !== undefined
                    ? { ...formData, city: newCity, zip_code: '' }
                    : { city: newCity, zip_code: '' },
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required={required}
            fullWidth
            error={showZipCodeError ?? false}
          >
            <TextField
              id={`${idSchema.$id}${idSeparator}zipcode`}
              name={`${idSchema.$id}${idSeparator}zipcode`}
              required={required}
              disabled={readonly}
              error={showZipCodeError ?? false}
              variant="outlined"
              label={zipCodeFieldTitle ?? 'Zip Code'}
              value={zipCode ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newZipCode = event.target.value
                onChange(
                  formData !== undefined
                    ? { ...formData, zip_code: newZipCode }
                    : { zip_code: newZipCode },
                )
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RJSFAddressField
