import { useContext } from 'react'
import FirmUser from 'models/FirmUser'
import LawyerUser from 'models/LawyerUser'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import LawyerLegalMatter from './LawyerLegalMatter'
import SubscriberLegalMatter from './SubscriberLegalMatter'

const LegalMatterDetail = (): JSX.Element | null => {
  const { user } = useContext(UserProfileContext)

  if (user instanceof LawyerUser || user instanceof FirmUser) {
    return <LawyerLegalMatter />
  }

  return <SubscriberLegalMatter />
}

export default LegalMatterDetail
